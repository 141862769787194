export const getLogoByDomain = () => {
    const domainLogoMapping = {
        'jh20.in': '/other/logo.png',
        '20wickets.in': '/other/20wickets.png',
        // 'rajexch.in': '/other/rajexch.png',
    };

    // Get the current domain
    const currentDomain = window.location.hostname;

    // Return the corresponding logo or a default one
    return domainLogoMapping[currentDomain] || '/other/logo.png';
};
