import '../../App.css';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { emptyUserInfo } from '../../redux/slice/userInfo/userInfoSlice';
import { emptyBalance } from '../../redux/slice/user/userSlice';
import { emptyLoingUserData } from '../../redux/slice/userData/userDataSlice';
import { emptyOpenBets } from '../../redux/slice/openBet/openBetSlice';
import { useDispatch } from 'react-redux';
import { NotificationContainer, NotificationManager } from 'react-notifications';

const AUTO_LOGOUT_TIME = 2 * 60 * 60 * 1000; // 2 hours in milliseconds (for production)

// const AUTO_LOGOUT_TIME = 1 * 60 * 1000; // 1 minutes for testing

const UserAutoLogout = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();

    useEffect(() => {
        const loginTime = localStorage.getItem('loginTime');

        if (!loginTime) {
            console.log("No login time found, auto logout is not active.");
            return;
        }

        const currentTime = new Date().getTime();
        const remainingTime = AUTO_LOGOUT_TIME - (currentTime - Number(loginTime));

        if (remainingTime <= 0) {
            console.log("Session expired, logging out immediately.");
            logoutUser();
            return;
        }

        console.log(`Auto logout scheduled in ${remainingTime / 1000} seconds`);

        const timer = setTimeout(() => {
            logoutUser();
        }, remainingTime);

        return () => clearTimeout(timer); // Cleanup timeout on unmount

    }, [navigate, dispatch]);

    const logoutUser = () => {
        console.log("Auto logout triggered!");
        NotificationManager.error("Token Expired, Login Again", "", 2000);

        setTimeout(() => {
            localStorage.removeItem("loginTime");
            localStorage.removeItem("userdata");
            localStorage.removeItem("login_token");
            dispatch(emptyUserInfo());
            dispatch(emptyBalance());
            dispatch(emptyOpenBets());
            dispatch(emptyLoingUserData());
            navigate("/");
        }, 2000);
    };

    return <NotificationContainer />;
};

export default UserAutoLogout;
