import React, { createContext, useContext, useEffect, useRef, useState } from "react";
import WebSocketEventEmitter from "./eventEmitter"; // Import the EventEmitter

const WebSocketContext = createContext(null);

export const WebSocketProvider = ({ url, children }) => {
  const [socket, setSocket] = useState(null);
  const [socketStatus, setSocketStatus] = useState(false);
  const [messages, setMessages] = useState([]);
  const [marketDataMsg, setMarketDataMsg] = useState(null);

  const reconnectRef = useRef(null);

  useEffect(() => {
    let ws;
    let reconnectAttempts = 0;

    const connectWebSocket = () => {
      ws = new WebSocket(url);

      ws.onopen = () => {
        console.log("✅ WebSocket Connected");
        setSocketStatus(true);
        reconnectAttempts = 0;
        clearTimeout(reconnectRef.current);
      };

      ws.onmessage = (event) => {
        const data = JSON.parse(event.data);
        // console.log("📩 Message Received:", data);

        // if(data?.action == 'MARKET_UPDATE')
        // {
        //   setMarketDataMsg(data)
        // }
        // else
        // {
        //   setMessages(data);
        // }
        // Emit WebSocket messages globally using EventEmitter
        WebSocketEventEmitter.emit("message", data);
      };

      ws.onclose = () => {
        console.warn("❌ WebSocket Disconnected, attempting to reconnect...");
        setSocketStatus(false);

        reconnectAttempts++;
        const timeout = Math.min(5000, 3000 * reconnectAttempts);
        reconnectRef.current = setTimeout(connectWebSocket, timeout);
      };

      ws.onerror = (error) => {
        console.error("⚠️ WebSocket Error:", error);
        setSocketStatus(false);

        ws.close();
      };

      setSocket(ws);
    };

    connectWebSocket();

    return () => {
      ws?.close();
      clearTimeout(reconnectRef.current);
    };
  }, [url]);

  const sendMessage = (message) => {
    if (socket && socket.readyState === WebSocket.OPEN) {
      socket.send(JSON.stringify(message));
    } else {
      console.error("❌ WebSocket not connected");
    }
  };

  return (
    <WebSocketContext.Provider value={{ socket, messages, sendMessage,socketStatus,marketDataMsg }}>
      {children}
    </WebSocketContext.Provider>
  );
};

// Custom Hook to use WebSocket
export const useWebSocket = () => {
  return useContext(WebSocketContext);
};
