import '../../App.css'
import React, { useEffect, useRef } from "react";

function CasinoVideo({ video_id }) {

    return (

        <iframe
            src={`https://universal.operator.buzz/universe_casino/?streamId=${video_id}`}
            width="100%"
            height="100%"
            style={{ border: "none" }}
            allow="autoplay"
            className='tviframe lg:h-[55vh] max-md:h-[27vh] w-screen'
        />
        // <video className='casinovideo' id="remoteVideo" width="100%" height="100%" autoplay="" playsinline="" muted="" style={{  backgroundSize: 'contain' }}>

        // </video>
    )

}

export default CasinoVideo;

